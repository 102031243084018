import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import {
  Button,
  HStack,
  Primitive,
  useToastContext,
} from '@rtkwlf/fenrir-react';
import RiskTable from './RiskTable/index';
import { RowTileV2 } from '../../Reusables/RowTileV2';
import { connect, useSelector } from 'react-redux';
import { Dispatch, iState } from '../../configureStore';
import { iRisk, iRiskMeta } from '../../Global/riskReducer';
import { OPEN_INFO_PANE } from '../../InfoPanes/infoPaneActions';
import {
  getCSVThunk,
  getRemediationCSVThunk,
  getRisksThunk_V2,
  SET_ACTION_LIST,
} from '../../Global/riskActions';
import { iUser } from '../../Global/userReducer';
import { RisksParams } from '../../types/risks';
import BulkUpdate from '../../Modals/Modals/BulkUpdate';
import { SelectType } from '../../Reusables/Filters';
import queryString, { ParsedQuery } from 'query-string';
import {
  DEFAULT_FILTER_STATE,
  READONLY_STATUS,
  RISK_STATES,
  SOURCE,
  STATUS,
} from '../../Constants/Risks';
import _ from 'lodash';
import Filters from '../../Reusables/Filters/index';
import DownloadCSVIcon from '../../Reusables/DownloadCSVIcon';
import { EXPORT_CSV_TOOLTIP, SortState } from '../../Constants/Common';
import { useEnrichRisks } from './utils';
import { customerSelectors, iCustomer } from '../../Global/customerReducer';
import { TagType, useQueryAllTags } from '../../Global/assetTags';
import { useMutation } from '@tanstack/react-query';
import riskAPI from '../../utils/riskAPI';
import { AxiosResponse } from 'axios';
import { useLocalStorage } from '@uidotdev/usehooks';

const FilterDiv = styled.div`
  margin-left: -10px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const RiskContainer = styled(Primitive.div)`
  margin-right: -20px;
`;

export const UNASSIGNED = { label: '--unassigned--', value: '_unassigned_' };

type StateProps = {
  customerId: string;
  meta: iRiskMeta;
  users: Array<iUser>;
};

type DispatchProps = {
  getCSV: (params?: RisksParams) => void;
  getRemediationCSV: (params?: RisksParams) => void;
  openPane: (riskId: string) => void;
  updatePage: (params?: RisksParams) => void;
};

type OwnProps = {
  description: string;
  initialFilters: RisksParams;
  filterDescription: React.ReactNode;
  title?: string;
  unassigned?: boolean;
};

type groupedIVA = { [key: string]: iRisk[] };
type HostData = {
  source: string;
  hosts?: string[];
  clientuuids?: string[];
  ScanName?: string;
  ScanType?: string;
};
type RescanObject = HostData & { uuid: string };

export type Props = StateProps & DispatchProps & OwnProps;

export const ActionList = ({
  description,
  initialFilters,
  getCSV,
  getRemediationCSV,
  openPane,
  meta,
  title,
  updatePage,
  users,
  customerId,
}: Props) => {
  const [selected, setSelected] = useState<Array<iRisk>>([]);
  const [bulkEditing, setBulkEditing] = useState(false);
  const [rescanIsLoading, setRescanIsLoading] = useState<boolean>(false);
  const [currentCustomer] = useState(
    useLocalStorage<iCustomer>('currentCustomer')?.[0]
  );
  const { addToast } = useToastContext();

  // Risks with asset criticality, tags & OS
  const riskArray = useEnrichRisks();

  const customerSwitchInProgress = useMemo(() => {
    if (currentCustomer?.id) {
      const urlParams = new URLSearchParams(window.location.search);
      const newCustomer = urlParams.get('id');
      return Boolean(newCustomer) && newCustomer !== currentCustomer.id;
    }
    return false;
  }, [currentCustomer]);

  const formattedUsers: SelectType[] = users.map((user: iUser) => ({
    value: user.email,
    label: `${user.firstname?.slice(0, 1)}.${user.lastname}`,
  }));
  formattedUsers.unshift(UNASSIGNED);

  const updateSelected = () => {
    if (selected.length > 0) {
      setBulkEditing(true);
    }
  };

  const setRiskSelected = (
    risk: iRisk,
    event: React.FormEvent<HTMLInputElement>
  ) => {
    if (event.currentTarget.checked) {
      setSelected([...selected, risk]);
    } else {
      setSelected(selected.filter((l_risk) => l_risk.id !== risk.id));
    }
  };

  const selectAllRisks = (checked: boolean) => {
    if (checked) {
      setSelected([
        ...riskArray.filter(
          (risk) => !READONLY_STATUS.includes(risk.attributes.status)
        ),
      ]);
    } else {
      clearSelected();
    }
  };

  const clearSelected = () => {
    setSelected([]);
  };

  const onChange = <K extends keyof RisksParams>(
    key: K,
    value: RisksParams[K]
  ) => {
    const newFilters: RisksParams = { ...filterObj };
    newFilters[key] = value;
    if (key !== 'page') {
      newFilters.page = 0;
    }
    setUrlFilters(newFilters);
    setFilters(newFilters);
  };

  const onSort = (column: string, sortState: SortState) => {
    const newFilters: RisksParams = { ...filterObj };

    if (sortState === 'none') {
      delete newFilters.order;
      delete newFilters.direction;
    } else {
      newFilters.order = column;
      newFilters.direction = sortState === 'asc' ? sortState : 'desc';
    }

    setUrlFilters(newFilters);
    setFilters(newFilters);
  };

  const onRowClick = (riskId: string) => {
    return openPane(riskId);
  };

  const { data: tags } = useQueryAllTags(
    useSelector(customerSelectors.getCustomerId)
  );

  const [filterObj, setFilters] = useState<RisksParams>({
    ...(initialFilters ? initialFilters : {}),
    ...getUrlFilters(
      queryString.parse(window.location.search, { parseNumbers: true }),
      tags
    ),
  });

  React.useEffect(() => {
    setFilters({
      ...(initialFilters ? initialFilters : {}),
      ...getUrlFilters(
        queryString.parse(window.location.search, { parseNumbers: true }),
        tags
      ),
    });
  }, [tags, setFilters, initialFilters]);

  const delayedUpdate = React.useRef(
    _.debounce((nFitlers: RisksParams) => {
      updatePage(nFitlers);
    }, 500)
  ).current;

  React.useEffect(() => {
    delayedUpdate(filterObj);
  }, [filterObj, delayedUpdate]);

  const isInitialMount = React.useRef(true);

  React.useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      onFilterChange({ ...DEFAULT_FILTER_STATE, ...initialFilters });
    }
  }, [customerId, delayedUpdate, initialFilters]);

  const onFilterChange = (updatedFilters: RisksParams) => {
    const riskFilters: RisksParams = {
      ...updatedFilters,
      page: 0,
    };
    setSelected([]);
    setUrlFilters(riskFilters);
    setFilters(riskFilters);
  };

  const rescanMutation = useMutation({
    mutationFn: (rescanObject: RescanObject) => {
      const hostData: HostData = {
        hosts: rescanObject.hosts || [],
        source: rescanObject.source,
        clientuuids: rescanObject.clientuuids || [],
      };

      if (rescanObject.ScanName) hostData.ScanName = rescanObject.ScanName;
      if (rescanObject.ScanType) hostData.ScanType = rescanObject.ScanType;

      return riskAPI.post(
        `/rootsecure/v1/sensor/${rescanObject.uuid}/scannow`,
        `hostdata=${JSON.stringify(hostData)}`
      );
    },
  });

  const groupIvaItemsByScannerId = (ivaItems: iRisk[]) => {
    const groupedObj: groupedIVA = {};
    for (const item of ivaItems) {
      const scannerID = item.attributes.scannerID;
      if (groupedObj[scannerID] === undefined) groupedObj[scannerID] = [];
      groupedObj[scannerID].push(item);
    }
    return groupedObj;
  };

  const rescanSelectedRisks = async (): Promise<void> => {
    setRescanIsLoading(true);

    // safe guard while EVA rescan is not yet functional
    if (selected.some((risk) => risk.source === SOURCE.REACH)) {
      setRescanIsLoading(false);
      setSelected([]);
      addToast({
        title: 'Rescan Request',
        message: 'This functionality is currently not available for EVA.',
        appearance: 'danger',
        duration: 20,
      });
      return Promise.resolve();
    }

    const requestObjects: Promise<AxiosResponse<any, any>>[] = [];
    const agentItems = selected.filter((risk) => risk.source === SOURCE.AGENT);
    const ivaItems = groupIvaItemsByScannerId(
      selected.filter((risk) => risk.source === SOURCE.SENSOR)
    );

    if (Object.keys(ivaItems).length > 0) {
      for (const [key, scanners] of Object.entries(ivaItems)) {
        requestObjects.push(
          rescanMutation.mutateAsync({
            uuid: key,
            source: 'sensor',
            hosts: scanners.map((scanner) => scanner.host),
          })
        );
      }
    }

    if (agentItems.length > 0) {
      requestObjects.push(
        rescanMutation.mutateAsync({
          uuid: agentItems[0].customerID,
          source: 'scout',
          ScanType: 'vulnerability',
          ScanName: '',
          clientuuids: agentItems.map((item) => item.attributes.clientUUID!),
        })
      );
    }

    return Promise.all(requestObjects)
      .then(() => {
        addToast({
          title: 'Rescan Request',
          message: 'The request to rescan the selected risk(s) has started.',
          appearance: 'success',
          duration: 20,
        });
      })
      .catch(() => {
        addToast({
          title: 'Rescan Request',
          message: 'The request to rescan the selected risk(s) has failed.',
          appearance: 'danger',
          duration: 20,
        });
      })
      .finally(() => {
        setRescanIsLoading(false);
        setSelected([]);
      });
  };

  return (
    <div>
      <RiskContainer>
        <FilterDiv>
          <Filters
            users={formattedUsers}
            state={filterObj.state}
            status={filterObj.status}
            showActionFilter
            issueAction={filterObj.issueAction}
            showResolvedDate
            filters={filterObj}
            handleFilters={onFilterChange}
            defaultRiskScores={[...Array(10).keys()].map((i) => i + 1)}
          />
        </FilterDiv>
        <RowTileV2
          data-testid='table-row-header'
          id='Risks'
          title={title || 'Risks'}
          description={description}
          buttons={
            <HStack gap='xsmall'>
              <Button
                data-testid='risks-table-remediation-report'
                variant='secondary'
                marginTop='small'
                isDisabled={!riskArray.length}
                onClick={() => getRemediationCSV(filterObj)}
              >
                Remediation Export
              </Button>
              <div data-testid='risks-table-export'>
                <DownloadCSVIcon
                  tooltipText={EXPORT_CSV_TOOLTIP}
                  handleExport={() => !riskArray.length || getCSV(filterObj)}
                  disabled={!riskArray.length}
                />
              </div>
            </HStack>
          }
        >
          <RiskTable
            riskArray={customerSwitchInProgress ? [] : riskArray}
            highlightList={selected.map((risk) => risk.id)}
            nextPage={meta?.next}
            onSort={onSort}
            page={filterObj?.page}
            pageSize={meta?.pageSize}
            rowClick={onRowClick}
            onChange={onChange}
            selectRisk={setRiskSelected}
            selectAll={selectAllRisks}
            clearAllSelected={clearSelected}
            updateRisks={updateSelected}
            setPage={(page: number) => onChange('page', page)}
            totalItems={meta?.total}
            rescanBtnDisabled={selected.some((risk) => risk.source === 'reach')}
            rescanSelected={rescanSelectedRisks}
            rescanIsLoading={rescanIsLoading}
          />
        </RowTileV2>

        <BulkUpdate
          isOpen={bulkEditing}
          onClose={() => setBulkEditing(false)}
          selectedRisks={selected}
          updatePage={() => updatePage(filterObj)}
        />
      </RiskContainer>
    </div>
  );
};

const mapStateToProps = ({ risk, user, customer }: iState): StateProps => {
  return {
    customerId: customer.currentCustomer.id,
    meta: risk.meta.assigned,
    users: user.users,
  };
};

const prepareAPIParams = (params: RisksParams, unassigned?: boolean) => {
  const filters = { ...params };
  if (!filters.status || filters.status.length === 0) {
    filters.status = [
      STATUS.ACTIVE,
      STATUS.INACTIVE,
      STATUS.MITIGATED,
      STATUS.OBSOLETE,
    ];
  }
  if (unassigned) {
    filters.assigned = false;
  }
  return filters;
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  { unassigned }: OwnProps
): DispatchProps => {
  return {
    getCSV: (params: RisksParams = {}) =>
      dispatch(getCSVThunk(false, prepareAPIParams(params, unassigned))),
    getRemediationCSV: (params: RisksParams = {}) =>
      dispatch(getRemediationCSVThunk(prepareAPIParams(params, unassigned))),
    openPane: (riskId: string) => {
      dispatch({
        type: OPEN_INFO_PANE,
        payload: {
          pane: 'RiskPane',
          width: '400px',
          title: 'Risk Details',
          payload: {
            riskId,
          },
        },
      });
    },
    updatePage: (params: RisksParams = {}) => {
      dispatch(
        getRisksThunk_V2(prepareAPIParams(params, unassigned), SET_ACTION_LIST)
      );
    },
  };
};

export default connect<StateProps, DispatchProps, OwnProps, iState>(
  mapStateToProps,
  mapDispatchToProps
)(ActionList);

const getTagsByName = (tags: TagType[] | undefined) => {
  const tagMap: { [name: string]: TagType } = {};
  tags?.forEach((tag) => {
    tagMap[tag.name] = tag;
  });
  return tagMap;
};

const getUrlFilters = (
  inputFilters: ParsedQuery<string | number> | undefined,
  tags: TagType[] | undefined
) => {
  // omit id from url filters since this is used for customer id, not risk id
  inputFilters = _.omit(inputFilters, 'id');
  const urlFilters: RisksParams = {
    search: '',
    fromLevel: 4,
    toLevelInclusive: 10,
    source: [SOURCE.SENSOR, SOURCE.REACH, SOURCE.AGENT],
    status: [],
    issueAction: '',
    state: [],
    owner: [],
    tagID: [],
    criticality: [],
    firstIdentifiedAfter: undefined,
    firstIdentifiedBefore: undefined,
    firstScannedAfter: undefined,
    firstScannedBefore: undefined,
    resolvedAfter: undefined,
    resolvedBefore: undefined,
  };
  // adjust default state if there are no url params
  if (inputFilters && Object.keys(inputFilters).length === 0) {
    urlFilters.state = [
      RISK_STATES.OPEN,
      RISK_STATES.IN_PROGRESS,
      RISK_STATES.ACKNOWLEDGED,
      RISK_STATES.FAILED_VALIDATION,
      RISK_STATES.FIXED,
    ];
    urlFilters.status = [STATUS.ACTIVE, STATUS.INACTIVE];
  }
  if (!inputFilters) {
    return urlFilters;
  }
  if (inputFilters.tag) {
    const tagsByName = getTagsByName(tags);
    if (!Array.isArray(inputFilters.tag)) inputFilters.tag = [inputFilters.tag];
    urlFilters.tagID = inputFilters.tag?.map(
      (tagName) => tagsByName[tagName!]?.tagID
    );
  }
  return { ...urlFilters, ...inputFilters };
};

const setUrlFilters = (filters: RisksParams): void => {
  const urlParams: RisksParams = {
    state: filters.state,
    status: filters.status,
    source: filters.source,
    owner: filters.owner,
    firstIdentifiedBefore: filters.firstIdentifiedBefore,
    firstIdentifiedAfter: filters.firstIdentifiedAfter,
    firstScannedBefore: filters.firstScannedBefore,
    firstScannedAfter: filters.firstScannedAfter,
    resolvedAfter: filters.resolvedAfter,
    resolvedBefore: filters.resolvedBefore,
    fromLevel: filters.fromLevel,
    toLevelInclusive: filters.toLevelInclusive,
    page: filters.page,
    tagID: filters.tagID,
    criticality: filters.criticality,
  };
  if (!!filters.adsAssetId) {
    urlParams.adsAssetId = filters.adsAssetId;
  }
  if (!!filters.search) {
    urlParams.search = filters.search;
  }
  if (!!filters.issueAction) {
    urlParams.issueAction = filters.issueAction;
  }
  const stringified = queryString.stringify(urlParams);
  let newUrl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname;
  if (stringified) newUrl += '?' + stringified;
  window.history.pushState({ path: newUrl }, '', newUrl);
};
