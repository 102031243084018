import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch, iState } from '../../configureStore';
import { RISK_SELECTABLE_STATES, STATUS } from '../../Constants/Risks';
import moment from 'moment';

import { bulkUpdateRisksThunk } from '../../Global/riskActions';
import {
  Button,
  Field,
  HStack,
  Primitive,
  Select,
  Text,
  VStack,
} from '@rtkwlf/fenrir-react';

import '../modal.css';
import DatePicker from 'react-datepicker';
import { iRisk } from '../../Global/riskReducer';
import { ModalBox, ModalPositionType } from '../../Reusables/ModalBox';
import { AcceptRisk } from './AcceptRisk';

interface Props extends PropsFromRedux {
  isOpen: boolean;
  selectedRisks: Array<iRisk>;
  onClose: () => void;
  updatePage: () => void;
}

const BulkUpdate = ({
  cases,
  users,
  update,
  selectedRisks,
  onClose,
  isOpen,
  updatePage,
}: Props) => {
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [selectedState, setSelectedState] = useState<string>();
  const [selectedUser, setSelectedUser] = useState<string>();
  const [selectedCase, setSelectedCase] = useState<string>();
  const [selectedDueDate, setSelectedDueDate] = useState<Date | null>();

  const isUnassigned = selectedUser
    ? selectedUser === '- Unassign -'
    : // if no user is selected in the current modal, check if everything already has a owner
      selectedRisks.some((risk) => !risk.attributes.assignment?.owner);

  const isChanged = !!(
    selectedState ||
    selectedUser ||
    selectedCase ||
    selectedDueDate
  );

  useEffect(() => {
    if (isUnassigned) {
      setSelectedDueDate(null);
    }
  }, [isUnassigned]);

  const allRisksInactive = selectedRisks.every(
    (risk) => risk.attributes.status === STATUS.INACTIVE
  );

  const riskStates = allRisksInactive
    ? [...Object.values(RISK_SELECTABLE_STATES), 'Mitigated']
    : [...Object.values(RISK_SELECTABLE_STATES)];

  const handleSubmit = () => {
    const riskUpdate: { [key: string]: string } = {};
    const caseAssignment: { [key: string]: string } = {};
    const caseUpdate: { [key: string]: string } = {};
    if (selectedState) riskUpdate['state'] = selectedState;
    if (selectedCase) caseAssignment['selectedCase'] = selectedCase;
    if (selectedUser) caseUpdate['owner'] = selectedUser;
    if (selectedDueDate)
      caseUpdate['dueDate'] = moment(selectedDueDate).format(
        'YYYY-MM-DDTHH:mm:ss[Z]'
      );
    if (riskUpdate.state === 'Accepted') {
      delete riskUpdate['state'];
      setShowAcceptModal(true);
    } else {
      update(riskUpdate, caseAssignment, caseUpdate, selectedRisks, updatePage);
    }

    onClose();
  };

  return (
    <>
      <ModalBox
        isOpen={isOpen}
        width='640px'
        onCloseModal={onClose}
        title={`Bulk Update - ${selectedRisks.length} Risk${selectedRisks.length > 1 ? 's' : ''} Selected`}
        position={ModalPositionType.top}
        footer={
          <HStack yAlign='center' xAlign='right'>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              className='update-risks'
              onClick={handleSubmit}
              isDisabled={!isChanged}
            >
              Update
            </Button>
          </HStack>
        }
      >
        <VStack paddingX='large' paddingBottom='large'>
          <HStack
            yAlign='center'
            width='full'
            data-testid='risk-bulk-update-modal'
          >
            <Primitive.div width='full'>
              <Field.Root appearance='neutral'>
                <Field.Label>State</Field.Label>
                <Field.Content>
                  <Select
                    className='status-dropdown'
                    value={selectedState}
                    options={Object.values(riskStates).map((value) => ({
                      text: value,
                      value,
                    }))}
                    onValueChange={(selection) => setSelectedState(selection)}
                  />
                </Field.Content>
              </Field.Root>
            </Primitive.div>

            <Primitive.div width='full'>
              <Field.Root appearance='neutral'>
                <Field.Label>Assign To</Field.Label>
                <Field.Content>
                  <Select
                    value={selectedUser}
                    options={[{ email: '- Unassign -' }, ...users].map(
                      (user) => ({
                        text: user.email,
                        value: user.email,
                      })
                    )}
                    onValueChange={(selection) => setSelectedUser(selection)}
                  />
                </Field.Content>
              </Field.Root>
            </Primitive.div>
          </HStack>
          <HStack yAlign='top' width='full' marginTop='large'>
            <Primitive.div width='full'>
              <Field.Root appearance='neutral'>
                <Field.Label>Plan</Field.Label>
                <Field.Content>
                  <Select
                    value={selectedCase}
                    options={cases.map((c) => ({
                      text: c.title,
                      value: c.id,
                    }))}
                    onValueChange={(selection) => setSelectedCase(selection)}
                  />
                </Field.Content>
              </Field.Root>
            </Primitive.div>
            <div>
              <Field.Root appearance={isUnassigned ? 'warning' : undefined}>
                <Field.Label>Due Date</Field.Label>
                <DatePicker
                  id='risk-pane-start-date'
                  disabled={isUnassigned}
                  selected={selectedDueDate}
                  onChange={(event: Date | null) => setSelectedDueDate(event)}
                  popperPlacement='left'
                />
                <Field.Message>
                  {isUnassigned &&
                    'Assign to a user before selecting a due date'}
                </Field.Message>
              </Field.Root>
            </div>
          </HStack>
        </VStack>
      </ModalBox>

      {showAcceptModal && (
        <ModalBox onCloseModal={() => setShowAcceptModal(false)}>
          <AcceptRisk
            selectedRisks={selectedRisks}
            closeModal={() => setShowAcceptModal(false)}
            updatePage={updatePage}
          />
        </ModalBox>
      )}
    </>
  );
};

const connector = connect(
  ({ cases, user }: iState) => ({
    cases: Array.from(cases.cases.values()) || [],
    users: user.users,
  }),
  (dispatch: Dispatch) => ({
    update: (
      riskUpdate: { [key: string]: string },
      caseAssignment: { [key: string]: string },
      caseUpdate: { [key: string]: string },
      selectedRisks: Array<iRisk>,
      updatePage?: () => void
    ) => {
      dispatch(
        bulkUpdateRisksThunk(
          riskUpdate,
          caseAssignment,
          caseUpdate,
          selectedRisks,
          updatePage
        )
      );
    },
  })
);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BulkUpdate);
