import React, { Fragment } from 'react';
//@ts-ignore
import * as echarts from 'echarts';
import ReactEcharts from 'echarts-for-react';
import { rs_echarts_theme } from '../RiskScoreTrends';
import { initAssetClassHealthCSV } from './utils';
import { Grid } from 'react-loader-spinner';
import { RowTile } from '../../../Reusables/RowTile';
import { BLUE } from '../../../Constants/Styles';
import { Button } from '@rtkwlf/fenrir-react';

const AssetHealth = ({ data }: any) => {
  var rsAssetScatterEntryArr: any = [];
  var assetClassArr: any = [];

  const newEntry = (name: string) => {
    var entry: any = {};

    entry.name = name;
    entry.type = 'scatter';

    entry.itemStyle = {};
    entry.itemStyle.opacity = 0.5;

    entry.tooltip = {
      trigger: 'item',
      formatter: function (params: any) {
        if (params.value.length > 1) {
          //get the info for the data point being triggered
          var vulns = params.value[0];
          var risk = params.value[1];

          //create a container for tooltip data
          // [
          //  {
          //   name: "AssetClassName",
          //   ips: [host, host, host, host, ...]
          //  }
          // ]
          var assetArr: any = [];
          //check for overlapping data points to add to the tooltip
          for (let i = 0; i < rsAssetScatterEntryArr.length; ++i) {
            var curEntry = rsAssetScatterEntryArr[i];
            for (let x = 0; x < curEntry.data.length; ++x) {
              var curName = curEntry.name;
              var curData = curEntry.data[x];

              var curVulns = curData[0];
              var curRisk = curData[1];
              var curHost = curData[2];

              //duplicate 2-tuple found
              if (vulns === curVulns && risk === curRisk) {
                //find existing asset if exists
                let curAsset: any = -1;
                for (let y = 0; y < assetArr.length; ++y) {
                  if (assetArr[y].name === curName) {
                    curAsset = assetArr[y];
                  }
                }

                //create a new asset for the tooltip
                if (curAsset === -1) {
                  var tipEntry: any = {};
                  tipEntry.name = curName;
                  tipEntry.ips = [];
                  tipEntry.ips.push(curHost);
                  assetArr.push(tipEntry);

                  //update an existing asset tooltip
                } else {
                  curAsset.ips.push(curHost);
                }
              }
            }
          }

          var instanceTip = '';
          for (let i = 0; i < assetArr.length; ++i) {
            const assetEntry: any = assetArr[i];
            instanceTip += assetEntry.name + ': ';
            for (let x = 0; x < assetEntry.ips.length; ++x) {
              instanceTip += assetEntry.ips[x];

              //comma separate ips
              if (x !== assetEntry.ips.length - 1) {
                instanceTip += ', ';
              }
              //tab in new rows under asset classes for visual distinction
              if (x > 0 && x % 5 === 0) {
                instanceTip += '<br/>&emsp;&emsp;&emsp;&emsp;';
              }
            }
            if (i !== assetArr.length - 1) {
              instanceTip += '<br/>';
            }
          }

          instanceTip += '<br/>#Vulns: ' + vulns + '<br/>Risk Score: ' + risk;

          return instanceTip;
        }
      },
    };

    entry.markPoint = {
      data: [
        {
          type: 'max',
          name: 'Max',
        },
        {
          type: 'min',
          name: 'Min',
        },
      ],
    };

    entry.markLine = {
      data: [
        {
          type: 'average',
          name: 'Avg',
        },
      ],
    };

    entry.data = [];
    return entry;
  };

  const json: any = data;

  if (json.length > 0) {
    // determine asset classes
    assetClassArr = Array.from(
      new Set(json.map((data: any) => data.assetclass.toLowerCase()))
    );

    // create an entry for each asset class
    for (let i = 0; i < assetClassArr.length; ++i) {
      var className = assetClassArr[i];
      rsAssetScatterEntryArr.push(newEntry(className));
    }

    // populate entry data
    for (let i = 0; i < rsAssetScatterEntryArr.length; ++i) {
      var entry = rsAssetScatterEntryArr[i];
      for (let x = 0; x < json.length; ++x) {
        var instanceName = json[x].assetclass.toLowerCase();
        if (instanceName === entry.name) {
          var vulns = json[x].countvulns;
          var risk = json[x].riskscore.toFixed(1);
          var host = json[x].host;
          entry.data.push([vulns, risk, host]);
        }
      }
    }
  }

  const chartOptions: echarts.EChartOption = {
    tooltip: {
      position: ['50%', '50%'],
      trigger: 'item',
      showDelay: 0,
      axisPointer: {
        type: 'cross',
        lineStyle: {
          type: 'dashed',
          width: 1,
        },
      },
      textStyle: {
        color: 'white',
      },
    },
    legend: {
      type: 'scroll',
      data: assetClassArr,
    },
    toolbox: {
      show: true,
      feature: {
        restore: {
          show: true,
          title: 'Restore',
        },
        saveAsImage: {
          show: false,
          title: 'Save Image',
        },
        dataView: {
          show: false,
          readOnly: true,
        },
      },
    },
    xAxis: [
      {
        name: 'Number of Vulnerabilities',
        nameTextStyle: { color: 'black' },
        axisLabel: { color: 'black' },
        nameLocation: 'middle',
        nameGap: 25,
        type: 'value',
        scale: true,
      },
    ],
    yAxis: [
      {
        name: 'Risk Score',
        nameTextStyle: { color: 'black' },
        axisLabel: { color: 'black' },
        nameLocation: 'middle',
        nameGap: 25,
        type: 'value',
        min: 0,
        max: 10,
      },
    ],
    series: rsAssetScatterEntryArr,
  };
  echarts.registerTheme('AWN', rs_echarts_theme);
  return (
    <Fragment>
      <RowTile
        id='asset-class-health'
        title={'Asset Class Health'}
        description={
          'The health of all of your assets divided by category, based on risk score and number of vulnerabilities. Assets that are closer to zero are healthier.'
        }
        buttons={
          <Button
            variant='secondary'
            id='export-asset-scatter-to-csv'
            onClick={() => {
              initAssetClassHealthCSV(rsAssetScatterEntryArr);
            }}
            iconLeft={<i className='fa fa-download' />}
          >
            CSV
          </Button>
        }
      >
        {data.length > 0 ? (
          <ReactEcharts option={chartOptions} theme={'AWN'} notMerge={true} />
        ) : (
          <div
            style={{
              margin: '75px 0px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid color={BLUE.tertiary} height={150} width={150} />
          </div>
        )}
      </RowTile>
    </Fragment>
  );
};

export default AssetHealth;
