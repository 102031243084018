import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  HStack,
  Menu,
  Popover,
  Primitive,
  Select,
  SelectOption,
  Text,
} from '@rtkwlf/fenrir-react';
import { RisksParams } from '../../../types/risks';
import {
  ffLittleWinsMR20241127,
  isFeatureEnabled,
} from '../../../../public/js/features';

const HEADERS: { [key: string]: string } = {
  source: 'Source',
  host: 'Host',
  issueName: 'Issue',
  issueLevel: 'Risk Score',
  criticality: 'Asset Criticality',
  os: 'OS',
  issueAction: 'Action',
  assetCategory: 'Asset Category',
  state: 'State',
  status: 'Status',
  statusReason: 'Status Reason',
  resolutionDate: 'Resolution Date',
  age: 'Age',
  daysToResolution: 'Days To Resolution',
  assetTags: 'Asset tags',
  deploymentID: 'Deployment ID',
};

if (isFeatureEnabled(ffLittleWinsMR20241127)) {
  HEADERS['mostRecentDetection'] = 'Most Recent Detection';
}

const RISK_PER_PAGE: SelectOption[] = [
  { value: '10', text: '10' },
  { value: '20', text: '20' },
  { value: '50', text: '50' },
  { value: '100', text: '100' },
  { value: '250', text: '250' },
  { value: '1000', text: '1000' },
  { value: '50000', text: 'All' },
];

// deepcode ignore HardcodedNonCryptoSecret: This is not actually a secret key, just a key used for localstorage value
const HIDDEN_COLUMN_KEYS_STORAGE_KEY = 'risk_table_hidden_columns';

const defaultColumnsToHide: string[] = [
  'source',
  'issueAction',
  'resolutionDate',
  'daysToResolution',
  'os',
  'deploymentID',
  'assetCategory',
  'statusReason',
];

if (isFeatureEnabled(ffLittleWinsMR20241127)) {
  defaultColumnsToHide.push('mostRecentDetection');
}

const getInitialHiddenColumns = () => {
  const hiddenColumnStorageKey = window.localStorage.getItem(
    HIDDEN_COLUMN_KEYS_STORAGE_KEY
  );

  if (hiddenColumnStorageKey) {
    const hiddenColumnStorageKeyArray: Array<string> = JSON.parse(
      hiddenColumnStorageKey
    );

    if (
      isFeatureEnabled(ffLittleWinsMR20241127) &&
      !hiddenColumnStorageKeyArray.includes('mostRecentDetection')
    ) {
      const updatedLocalStorageValue = [
        ...hiddenColumnStorageKeyArray,
        'mostRecentDetection',
      ];
      window.localStorage.setItem(
        HIDDEN_COLUMN_KEYS_STORAGE_KEY,
        JSON.stringify(updatedLocalStorageValue)
      );
      return updatedLocalStorageValue;
    }

    return hiddenColumnStorageKeyArray;
  }

  return defaultColumnsToHide;
};

/**
 * Builds table header content including page size control & custom column selection.
 * Also exposes the state of selected columns.
 */
export const useHeader = (
  pageSize: number | undefined,
  onChange: <K extends keyof RisksParams>(key: K, value: RisksParams[K]) => void
) => {
  const pageSizeValue = useMemo(
    () =>
      typeof pageSize !== 'undefined'
        ? { value: String(pageSize), label: String(pageSize) }
        : RISK_PER_PAGE[0],
    [pageSize]
  );

  const [hiddenColumnKeys, setHiddenColumnKeys] = useState<Array<string>>(
    getInitialHiddenColumns
  );

  const onHiddenColumnKeysChange = useCallback(
    (key: string, checked: boolean) => {
      setHiddenColumnKeys((prevHiddenKeys) => {
        let newKeys;
        if (checked) {
          newKeys = prevHiddenKeys.filter((k) => k !== key);
        } else {
          newKeys = [...prevHiddenKeys, key];
        }

        window.localStorage.setItem(
          HIDDEN_COLUMN_KEYS_STORAGE_KEY,
          JSON.stringify(newKeys)
        );

        return newKeys;
      });
    },
    []
  );

  const header = useMemo(() => {
    const DisplayColumnsDropdown = (
      <Popover.Root>
        <Popover.Trigger asChild>
          <Button
            margin='zero'
            variant='secondary'
            iconRight={<i className='fa fa-caret-down' />}
          >
            Columns
          </Button>
        </Popover.Trigger>
        <Popover.Content align='end'>
          <Menu.Root>
            {Object.entries(HEADERS).map(([key, value]) => (
              <Menu.Item key={key}>
                <Checkbox.Root
                  margin='zero'
                  name={key}
                  isChecked={!hiddenColumnKeys.includes(key)}
                  onCheckedChange={(isChecked: boolean) =>
                    onHiddenColumnKeysChange(key, isChecked)
                  }
                >
                  <Checkbox.Text>{value}</Checkbox.Text>
                </Checkbox.Root>
              </Menu.Item>
            ))}
          </Menu.Root>
        </Popover.Content>
      </Popover.Root>
    );

    return (
      <HStack xAlign='between' marginY='small'>
        <HStack gap='small'>
          <Text styledAs='body3'>Show</Text>
          <Primitive.div
            width='6rem'
            data-testid='select-pageLimit-TestSelector'
          >
            <Select
              isClearable={false}
              value={pageSizeValue.value}
              options={RISK_PER_PAGE}
              onChange={(value) => onChange('limit', parseInt(value || ''))}
            />
          </Primitive.div>
          <Text styledAs='body3'>Entries</Text>
        </HStack>
        {DisplayColumnsDropdown}
      </HStack>
    );
  }, [hiddenColumnKeys, onChange, onHiddenColumnKeysChange, pageSizeValue]);

  return {
    header,
    hiddenColumnKeys,
  };
};
